import { Injectable } from '@angular/core';
import { APP_CONTSTANTS, BROADCAST_MESSAGE_DIRECTION } from '../models/AppConstants';
import { IMessage } from '../models/IMessage';
import { Subject, TimeInterval } from 'rxjs';
import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})

export class GenesysService {

  private _bc: any;
  public message: Subject<IMessage>;
  private _heartbeatTimer: any;
  private _heartbeatReceiveTimeout: any;

  private _fileName = 'genesys.service.ts';

  constructor(
    private configurationervice: ConfigurationService,
    private _lgSrvc: LoggerService
  ) {

    this.message = new Subject();

    try {
      this._bc = new BroadcastChannel(APP_CONTSTANTS.BroadcastChannelName);
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    try {
      this._bc = new BroadcastChannel(APP_CONTSTANTS.BroadcastChannelName);

      this._bc.onmessage = (bcMessage: any) => {
        const fnName = 'bc.onmessage';
        this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
        try {
          let message = bcMessage.data as IMessage;
          if (message.messageDirection === BROADCAST_MESSAGE_DIRECTION.FromWwe) {
            if (message.messageObject === APP_CONTSTANTS.MessageHeartBeat) {
              clearTimeout(this._heartbeatReceiveTimeout);
            }
            this.updateSubscribers(message);
          }
        } catch (error) {
          this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
        } finally {
          this._lgSrvc.logFnPerimeter(this._fileName, fnName);
        }

      };

      this._bc.onmessageerror = (error: any) => {
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }




    this._heartbeatTimer = setInterval(() => {
      const fnName = 'headrbeatTimer';
      try {
        this._lgSrvc.logger.logLoop(`${fnName} - ${this._fileName} - MESSAGE1: ${JSON.stringify('START')}`)
        this.sendMessageToWWE(APP_CONTSTANTS.MessageHeartBeat);
        this._heartbeatReceiveTimeout = setTimeout(() => {
          const fnName = 'heartbeatReceiveTimeout';
          this._lgSrvc.logger.logLoop(`${fnName} - ${this._fileName} - MESSAGE1: ${JSON.stringify('START')}`)
          try {
            let disconnecMessage: IMessage = {
              messageDirection: BROADCAST_MESSAGE_DIRECTION.FromWwe,
              messageObject: APP_CONTSTANTS.MessageDisconnected
            }
            this.updateSubscribers(disconnecMessage);
          } catch (error) {
            this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
          } finally {
            this._lgSrvc.logger.logLoop(`${fnName} - ${this._fileName} - MESSAGE1: ${JSON.stringify('END')}`)
          }
        }, APP_CONTSTANTS.HearbeatTimeoutInMilliSec);
      } catch (error) {
        this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
      } finally {
        this._lgSrvc.logger.logLoop(`${fnName} - ${this._fileName} - MESSAGE1: ${JSON.stringify('END')}`)
      }
    }, APP_CONTSTANTS.HearbeatIntervalInMilliSec);
  }

  ngOnDestroy(): void {
    const fnName = 'ngOnDestroy';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      clearTimeout(this._heartbeatReceiveTimeout);
      clearInterval(this._heartbeatTimer);
      this._bc.close();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  sendHeartBeatResultToSubscribers(success: boolean) {
  }

  /**
  * Updates the observable and consequently the subscribers
  * @param message message: the message that will be sent to the subscribers
  */
  updateSubscribers(message: IMessage) {
    const fnName = 'updateSubscribers';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.message.next(message);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }

  }

  /**
  * Uses broadcastchannel to send messages to WWE Frame
  * @param messageString messageString: defines the type of a message
  * @param additionalData? additionalData: for some requests you need to provide additional data. For example. For
  * example to update an interaction you need to pass interactionID
  * @param keyValues? keyValues: the data you need to update the interaction with
  */
  sendMessageToWWE(messageString: string, additionalData?: string, keyValues?: { [key: string]: string }) {
    const fnName = 'sendMessageToWWE';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      let bcMessage: IMessage = {
        messageDirection: BROADCAST_MESSAGE_DIRECTION.ToWwe,
        //TODO update hardcoded
        messageObject: messageString,
      }
      if (additionalData) {
        bcMessage.additionalData = additionalData;
      }
      if (keyValues) {
        bcMessage.keyValues = keyValues;
      }
      this._bc.postMessage(bcMessage);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }
}
