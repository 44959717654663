import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AmcGenesysEngageWweHomeComponent } from './amc-genesys-engage-wwe-home/amc-genesys-engage-wwe-home.component';
import { WweHostedComponent } from './wwe-hosted/wwe-hosted.component';
import { AppComponent } from './app.component';
import{ initServicesFactory } from './_factoryFunctions/init-services-factory';
import { LoggerService } from './_services/logger.service';
import { ConfigurationService } from './_services/configuration.service';

@NgModule({
  declarations: [
    AppComponent,
    WweHostedComponent,
    AmcGenesysEngageWweHomeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: AmcGenesysEngageWweHomeComponent, pathMatch: 'full' },
      { path: 'wwe-frame', component: WweHostedComponent },
    ])
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initServicesFactory,
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
