import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GenesysService } from '../_services/genesys.service'
import { IMessage } from '../models/IMessage';
import { APP_CONTSTANTS, BROADCAST_MESSAGE_DIRECTION, CHANNEL_WWE_TO_AMC, IDetails, STATE_WWE_TO_AMC, WWE_AGENT_REQUESTS, WWE_INTERACTION_EVENT_TYPE, WWE_INTERACTION_REQUESTS, WWW_EVENT_TYPES } from '../models/AppConstants';
import { CHANNEL_TYPES, IInteraction, INTERACTION_DIRECTION_TYPES, INTERACTION_STATES as AMC_INTERACTION_STATES, NOTIFICATION_TYPE, RecordItem, registerOnLogout, initializeComplete, sendNotification, setAppHeight, setInteraction } from '@amc-technology/davinci-api';
import { LoggerService } from '../_services/logger.service';

@Component({
  selector: 'amc-genesys-engage-wwe-home',
  templateUrl: './amc-genesys-engage-wwe-home.component.html',
  styleUrls: ['./amc-genesys-engage-wwe-home.component.css']
})
export class AmcGenesysEngageWweHomeComponent implements OnInit, OnDestroy {

  public connectedToWWE: boolean;
  private _fileName = "amc-genesys-engage-wwe-home.component.ts";

  constructor(
    private _genesysSrvc: GenesysService,
    private _lgSrvc: LoggerService,
    private _cd: ChangeDetectorRef
  ) {
    this.connectedToWWE = false;
  }

  async ngOnInit(): Promise<void> {
    const fnName = 'ngOnInit';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    try {
      setAppHeight(10);
      this._genesysSrvc.message.subscribe(message => {
        this.processMessage(message);
      });
      registerOnLogout(async () => {
        await this._lgSrvc.logger.pushLogsAsync();
      });
      await initializeComplete();
      
      //TODO: Do we need this?
      // window.addEventListener("beforeunload", (ev) => {
      //   this.genesys.cleanup();
      //   this.genesys.message.unsubscribe();
      // });
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  ngOnDestroy(): void {
    const fnName = 'ngOnDestroy';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this._genesysSrvc.message.unsubscribe();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }

  }

  /**
  * Prcesses messages received from genesysService
  * @param message message: message received from genesysService
  */
  processMessage(message: IMessage) {
    const fnName = 'processMessage';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      if (message.messageObject === APP_CONTSTANTS.MessageHeartBeat) {
        // We must recognize that we were previously disconnected, but now we are connected, and therefore, we need to perform an initialization.
        if (this.connectedToWWE) {
          return;
        }
        sendNotification("Connected to WWE", NOTIFICATION_TYPE.Information);
        this.connectedToWWE = true;
        // We don't know why we should trigger this onChanges event. Posted a question on Stackoverflow. If it turns out to be an Angular issue:
        // We might need to report an issue on Angular github page.

        //We are connected to WWE. Therefore, we will subscribe to the events and initialize agent state + interactions
        this.retreiveInitialDataFromWWE();
        this._cd.detectChanges();
      } else if (message.messageObject === APP_CONTSTANTS.MessageDisconnected) {
        if (!this.connectedToWWE) {
          return;
        }
        sendNotification("Connection is lost", NOTIFICATION_TYPE.Error);
        this.connectedToWWE = false;
        // We don't know why we should trigger this onChanges event. Posted a question on Stackoverflow. If it turns out to be an Angular issue:
        // We might need to report an issue on Angular github page.
        this._cd.detectChanges();
      } else if (message.messageObject.request) {
        this.processRequestedMessage(message.messageObject);
      } else if (message.messageObject.event) {
        this.processEventMessage(message.messageObject);
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Prcesses messages with type request
  * @param message message: message with type request
  */
  processRequestedMessage(message: any) {
    const fnName = 'processRequestedMessage';
    this._lgSrvc.logFnPerimeter(fnName, this._fileName, true);
    try {
      switch (message.request) {
        // Currently we just require this request
        case WWE_INTERACTION_REQUESTS.GetAll:
          this.initializeInteractions(message.data);
          break;
        default:
          break;
      }
      //TODO: Do we need this?
      //this.cd.detectChanges();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Processes all the interactions when refreshing the page or when connection is stablished with WWE
  * @param data data: contains all the interactions
  */
  initializeInteractions(data: any) {
    const fnName = 'initializeInteractions';
    try {
      if (data) {
        data.forEach((element: any) => {
          this.processInteraction(element);
        });
      }
      //TODO: Do we need this?
      //this.cd.detectChanges();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }


  detectScenarioDirection() {
    // 1 inbound => direction: "IN", callType:"INBOUND", ani: "5115", isConsultation: false, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7012", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q"

    // 1 outbound => direction: "OUT", callType:"OUTBOUND", ani: "7012", isConsultation: false, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "DIALING", state: "TALKING", dnis: "5115", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q"

    // 1 Consultation Leg, Inbound Original Call (we are receiving agent) =>
    // direction: "IN", callType:"CONSULT", ani: "7001", isConsultation: true, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7012", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" DisplayContact : "Pat Thompson"

    // 1 Consultation Leg, Outbound Original Call (we are receiving agent) =>
    // direction: "IN", callType:"CONSULT", ani: "7001", isConsultation: true, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7012", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" DisplayContact : "Pat Thompson"

    // 1 Internal Inbound
    // Does not have userData->ContactId
    // direction: "IN", callType:"INTERNAL", ani: "7001", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7012", isCaseExpanded: true, isCaseSelected: true

    // 1 Internal Outbound
    // Does not have userData->ContactId
    // direction: "OUT", callType:"INTERNAL", ani: "7012", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "DIALING", state: "TALKING", dnis: "7001", isCaseExpanded: true, isCaseSelected: true

    //************************************************************** */
    // 1 Conference, We are the receving agent, Initial Inbound call
    // direction: "IN", callType:"INBOUND", ani: "5115", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7001", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" => Does not have DisplayContact


    // After customer hangs out => The parties will be updated! Almost nothing else

    //************************************************************** */
    // 1 Conference, We are the receving agent, Initial Outbound call
    // direction: "IN", callType:"OUTBOUND", ani: "7001", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "RINGING", state: "TALKING", dnis: "7001", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" => Does not have DisplayContact


    // After customer hangs out => The parties will be updated! Almost nothing else


    //************************************************************** */
    // 1 Conference, We are the initiating agent, Initial Inbound call
    // direction: "IN", callType:"INBOUND", ani: "5115", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "HELD", state: "TALKING", dnis: "7012", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" => Does not have DisplayContact
    // userData->GCS_ConferencingEmployeeId : "jkilfoil"


    // After receiving agent hangs out => The parties will be updated! Almost nothing else


    //************************************************************** */
    // 1 Conference, We are the initiating agent, Initial Outbound call
    // direction: "OUT", callType:"OUTBOUND", ani: "7012", isConsultation: FALSE, isMainCaseInteraction: true, media:
    // "voice", parentInteractionId: null, previousState: "HELD", state: "TALKING", dnis: "5115", isCaseExpanded: true, isCaseSelected: true
    // userData->ContactId = "0008Va76MGEC000Q" => Does not have DisplayContact
    // userData->GCS_ConferencingEmployeeId : "jkilfoil", PhoneNumber:"5115"


    // After receiving agent hangs out => The parties will be updated! Almost nothing else

  }

  /**
  * Prcesses messages with type event
  * @param message message: message with type event
  */
  processEventMessage(message: any) {
    const fnName = 'processEventMessage';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      switch (message.event) {
        case WWW_EVENT_TYPES.AGENT:
          break;
        case WWW_EVENT_TYPES.INTERACTION:
          this.processInteractionEvent(message);
          break;
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Filters messages that are event and interaction
  * @param message message: message with type event
  */
  processInteractionEvent(message: any) {
    const fnName = 'processInteractionEvent';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      switch (message.data.eventType) {
        case WWE_INTERACTION_EVENT_TYPE.CaseCollapsed:
        case WWE_INTERACTION_EVENT_TYPE.CaseExpanded:
        case WWE_INTERACTION_EVENT_TYPE.CaseSelected:
          break;
        default:
          if (!message.data || !message.data.interaction || !message.data.interaction.userData || (!message.data.interaction.caseId && !message.data.interaction.userData.IW_CaseUid)) {
            sendNotification("Something is wrong with the interaction");
          } else {
            this.processInteraction(message.data.interaction);
          }
          break;
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Prcesses interactions
  * @param interaction interaction
  */
  processInteraction(interaction: any) {
    const fnName = 'processInteraction';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName,);
    try {
      let scenarioId: string = (interaction.caseId || interaction.userData.IW_CaseUid);
      let interactionId: string = interaction.interactionId;
      const wwe_state = interaction.state;
      console.log(interaction);
      let state = STATE_WWE_TO_AMC.get(wwe_state);
      if (state === undefined) {
        //TODO LOG ERROR
        return;
      }
      const wwe_channel = interaction.media;
      let channelType = CHANNEL_WWE_TO_AMC.get(wwe_channel);
      if (channelType === undefined) {
        //TODO LOG ERROR
        return;
      }
      let direction = INTERACTION_DIRECTION_TYPES.Inbound;

      const newAmcInteraction: IInteraction = {
        interactionId: interactionId,
        scenarioId: scenarioId,
        state: state,
        channelType: channelType,
        direction: direction,
      };
      switch (channelType) {
        case CHANNEL_TYPES.Telephony:

          this.processVoiceInteraction(interaction, newAmcInteraction);
          break;
        case CHANNEL_TYPES.Chat:
          this.processChatInteraction(interaction, newAmcInteraction);
          break;
        default:
          break;
      }

      //TODO: Riase a notification to AMC notification UI
      // if (!this.ScenarioList.has(scenarioId)) {
      //   this.ScenarioList.set(scenarioId, new Set<string>);
      // }
      // let interactionList = this.ScenarioList.get(scenarioId);
      // if (interactionList && !interactionList.has(interactionId)) {
      //   interactionList.add(interactionId);
      // }

      setInteraction(newAmcInteraction);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Generates CAD data from interaction
  * @param interaction interaction
  */
  generateCadData(interaction: any) {
    const fnName = 'generateCadData';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    const defaultCADObject: IDetails = {};
    const defaultReturnValue = new RecordItem('', '', '', defaultCADObject);

    let details;
    try {
      const CADObject: IDetails = {};
      const userData = interaction.userData;
      Object.entries(userData)
        .forEach(([key, value]) => {
          if (value && (typeof value) !== 'object') {
            CADObject[key] = {
              DevName: '',
              DisplayName: '',
              Value: value
            };
          }
        });
      details = new RecordItem('', '', '', CADObject);
      return details;

    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
      return defaultReturnValue;
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName,)
    }

  }


  //-----------------------------------------------------------//
  //1- if more than 2 parties : CONFRENCE ->
  //    1-1 if has CS_TransferringEmployeeId, we are receiving agent
  //        1-1-1 if  callType:"OUTBOUND" the initial call was Outbound
  //        1-1-2 if  callType:"INBOUND" the initial call was Inbound
  //    1-2 if has GCS_ConferencingEmployeeId, we are initiating agent
  //        1-2-1 if  callType:"OUTBOUND" the initial call was Outbound
  //        1-2-2 if  callType:"INBOUND" the initial call was Inbound
  //2- if we have 1 party: CONSULT/INBOUND/INBOUND/INTERNAL
  //    2-1 callType:"INTERNAL"
  //        2-1-1: direction: "OUT" -> OUTBOUND INTERNAL
  //        2-1-2: direction: "IN" -> INBOUND INTERNAL
  //    2-2 callType:"CONSULT"
  //        2-2-1: direction: "OUT" -> OUTBOUND CONSULT
  //        2-2-2: direction: "IN" -> INBOUND CONSULT
  //    2-3 callType: "INBOUND" -> INBOUND
  //    2-4 callType: "OUTBOUND" -> OUTBOUND

  // TODO: make the function a bit shorter

  /**
  * Processes voice interaction
  * @param interaction interaction
  * @param newAmcInteraction Will be modified by the function
  */
  processVoiceInteraction(interaction: any, newAmcInteraction: IInteraction) {
    const fnName = 'processVoiceInteraction';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {

      newAmcInteraction.details = this.generateCadData(interaction);


      let phoneNumber: string = '';
      if (interaction.parties) {
        let parties = interaction.parties;
        let numberOfParties = Object.keys(parties).length;

        if (numberOfParties > 1) {
          if (interaction.callType === "OUTBOUND") {
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Outbound;
            phoneNumber = interaction.dnis;
          } else if (interaction.callType === "INBOUND") {
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound;
            phoneNumber = interaction.ani;
          } else {
            //TODO what is wrong?
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound;
            //TODO: make it better
            throw new Error("Unknown callType for multuParty interaction");
          }
        } else if (numberOfParties === 1) {
          if (interaction.callType === "INTERNAL") {
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Internal;
            if (interaction.direction === "OUT") {
              phoneNumber = interaction.dnis;
            } else if (interaction.direction === "IN") {
              phoneNumber = interaction.ani;
            } else {
              throw new Error("Unkown direction for INTERNAL call");
            }
          } else if (interaction.callType === "CONSULT") {
            if (interaction.direction === "OUT") {
              newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Outbound;
              phoneNumber = interaction.userData.customerNumber || "";
            } else if (interaction.direction === "IN") {
              newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound
              phoneNumber = interaction.userData.customerNumber || "";
            } else {
              //TODO Something is wrong?
              newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound;
              throw new Error("Unknown direction for CONSULT call");
            }
          } else if (interaction.callType === "INBOUND") {
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound;
            phoneNumber = interaction.ani;
            if (!interaction.userData.customerNumber) {
              let userNewData: { [key: string]: string } = { 'customerNumber': interaction.ani };
              this._genesysSrvc.sendMessageToWWE(WWE_INTERACTION_REQUESTS.SetUserData, newAmcInteraction.interactionId, userNewData);
            }
          }
          else if (interaction.callType === "OUTBOUND") {
            phoneNumber = interaction.dnis;
            newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Outbound;
            if (!interaction.userData.customerNumber) {
              let userNewData: { [key: string]: string } = { 'customerNumber': interaction.dnis };
              this._genesysSrvc.sendMessageToWWE(WWE_INTERACTION_REQUESTS.SetUserData, newAmcInteraction.interactionId, userNewData);
            }
          }
        }
      }
      //TODO need to move above?
      // It should be always a hit
      if (newAmcInteraction.details) {
        newAmcInteraction.details.setPhone('', '', phoneNumber);
      } else {
        throw new Error("AMCInteraction details are not constructed!")
      }

    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }

  }

  //-----------------------------------------------------------//
  //1- if more than 2 parties : CONFRENCE ->
  //    1-1 if has CS_TransferringEmployeeId, we are receiving agent
  //        1-1-1 if  callType:"OUTBOUND" the initial call was Outbound
  //        1-1-2 if  callType:"INBOUND" the initial call was Inbound
  //    1-2 if has GCS_ConferencingEmployeeId, we are initiating agent
  //        1-2-1 if  callType:"OUTBOUND" the initial call was Outbound
  //        1-2-2 if  callType:"INBOUND" the initial call was Inbound
  //2- if we have 1 party: CONSULT/INBOUND/INBOUND/INTERNAL
  //    2-1 It


  /**
  * Processes chat interaction
  * @param interaction interaction
  * @param newAmcInteraction Will be modified by the function
  */
  processChatInteraction(interaction: any, newAmcInteraction: IInteraction) {
    const fnName = 'processChatInteraction';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      newAmcInteraction.details = this.generateCadData(interaction);
      newAmcInteraction.direction = INTERACTION_DIRECTION_TYPES.Inbound;
      if (interaction.userData.EmailAddress) {
        newAmcInteraction.details.setEmail('', '', interaction.userData.EmailAddress);
      } else {
        throw new Error("Chat interaction does not have email!");
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }

  }

  /**
  * Sends getAgentDetails, getInteractions and agentGetState to genesys service
  * **NOTE:** getAgentDetails and agentGetState are not used now
  */
  retreiveInitialDataFromWWE(): void {
    const fnName = 'retreiveInitialDataFromWWE';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.getAgentDetails();
      this.getInteractions();
      this.agentGetState();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Send a message to GenesysService which consequently queries WWE for agent details
  */
  getAgentDetails() {
    const fnName = 'getAgentDetails';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this._genesysSrvc.sendMessageToWWE(WWE_AGENT_REQUESTS.Get);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Send a message to GenesysService which consequently queries WWE all the current interactions
  */
  getInteractions() {
    const fnName = 'getInteractions';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this._genesysSrvc.sendMessageToWWE(WWE_INTERACTION_REQUESTS.GetAll);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Send a message to GenesysService which consequently queries WWE for agent presence
  */
  agentGetState() {
    const fnName = 'agentGetState';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this._genesysSrvc.sendMessageToWWE(WWE_AGENT_REQUESTS.GetState);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

}
