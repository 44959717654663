import { ConfigurationService } from '../_services/configuration.service';
import { LoggerService } from '../_services/logger.service';
// import { GenesysService } from '../_services/genesys.service';

export function initServicesFactory(configurationService: ConfigurationService,
 loggerService: LoggerService) {
  return async () => {
    await configurationService.initialize();
    await loggerService.initialize();
  }
}
