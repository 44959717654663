import { IClientConfiguration } from './../models/IClientConfiguration';
import { Inject, Injectable } from '@angular/core';
import { Logger } from '@amc-technology/davinci-api';
import { HttpClient } from '@angular/common/http';
import { getConfig } from '@amc-technology/davinci-api';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly _configUrlPath: string = 'ClientConfiguration';
  public config = {} as any;
  public serverConfig = {} as any;
  private baseUrl: string;
  public appName = '';

  constructor(private _http: HttpClient,
    @Inject('BASE_URL') baseUrl: string
    ) {
      this.baseUrl = baseUrl;
    }

    async initialize(): Promise<void> {
      this.config = await getConfig();
      this.appName = this.config.appName ?? 'DAVINCI APP FOR GENESYS ENGAGE WWE';
      const serverConfig$ = this._http.get<IClientConfiguration>(`${this.baseUrl}ClientConfiguration`);
      this.serverConfig = await serverConfig$.toPromise();
    }
}
