import { INTERACTION_STATES as AMC_INTERACTION_STATES, CHANNEL_TYPES, IField } from "@amc-technology/davinci-api";

export class APP_CONTSTANTS {
    static readonly BroadcastChannelName: string = "AMC_GENESYS_WWE";
    static readonly MessageHeartBeat: string = "HEARTBEAT";
    static readonly MessageDisconnected: string = "NO_HEARTBEAT";

    static readonly HearbeatIntervalInMilliSec: number = 5000;
    static readonly HearbeatTimeoutInMilliSec: number = 2000;
}


export enum WWE_INTERACTION_STATES {
    // 'UNKNOWN' : , //An unknown state
    Idle = 'IDLE',//Specifies a non-active interaction which could be closed
    Ringing = 'RINGING', //The inbound call is ringing.
    Dialing = 'DIALING', //The outbound call is ringing
    Talking = 'TALKING', //The call is established.
    Held = 'HELD', //The call is on hold.
    Stopped = 'STOPPED', // Need to find what this state means********************
    // 'PREVIEW',//The interaction is a call preview.
    Invited = 'INVITED',//The open media interaction is inviting.
    Accepted = 'ACCEPTED',//The open media interaction is accepted.
    // 'CREATED',//The open media interaction has been created.
    // 'PULLED', INTERACTION_STATES.//The open media interaction has been pulled from a workbin.
    // 'REVOKED', INTERACTION_STATES.//The open media interaction has been revoked.
    Completed = 'COMPLETED', //The open media interaction has been completed (Mark as done).
    // 'ERROR',//The open media interaction has an error.
    // 'SAVED', //The open media interaction has been saved.
    // 'TRANSFERRING', //The open media interaction is being transferred.
    // 'TRANSFER_COMPLETED', //The open media interaction has been transferred and the transfer has been completed.
    // 'INVITED_CONFERENCE', //The open media interaction receives a conference invitation.
    // 'LEFT_CONFERENCE', //The open media interaction has left the conference.
    // 'USER_DATA_ATTACHED', //Data has been attached to the interaction.
    // 'USER_DATA_UPDATED', //The attached data has changed in the interaction.
    // 'JOIN_PENDING', //Trying to join the chat session.
    // 'JOIN_FAILED', //The connection with the chat server failed.
    // 'HISTORY_IN_PROGRESS', //Loading the content of the chat interaction.
    // 'HISTORY_DONE', //The content of the chat interaction has been loaded.
    // 'CANCELLED', //The outbound email is cancelled.
    // 'SENT', //The outbound email is sent.
    // 'READY', //The call preview is ready.
    // 'CANCELED', //The call preview is cancelled.
    // 'REJECTED', //The call preview is rejected.
}

export enum WWE_CHANNEL_TYPES {
    Voice = 'voice',
    Chat = 'chat'
}

export enum WWE_INTERACTION_REQUESTS {
    GetAll = 'interaction.getInteractions',
    GetById = 'interaction.getByInteractionId',
    SetUserData = 'interaction.setUserData',
    DeleteUserData = 'interaction.deleteUserData',
    SelectInteractionByCaseId = 'interaction.selectCaseByCaseId',
    MarkDone = 'interaction.markdone',
    BlockMarkdone = 'interaction.blockMarkdone',
    UnblockMarkDone = 'interaction.unblockMarkdone',
}

export enum WWE_AGENT_REQUESTS {
    Get = 'agent.get',
    GetList = 'agent.getStateList',
    SetState = 'agent.setState',
    GetState = 'agent.getState',
}

export enum WWW_EVENT_TYPES {
    AGENT = "agent",
    INTERACTION = "interaction",
}

export enum WWE_INTERACTION_EVENT_TYPE {
    CaseSelected = 'CASE_SELECTED',
    CaseExpanded = 'CASE_EXPANDED',
    CaseCollapsed = 'CASE_COLLAPSED',
}

export enum WWEInteractionDirection {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}

let c = typeof WWE_INTERACTION_STATES.Idle;

export const STATE_WWE_TO_AMC = new Map<string, number>([
    [WWE_INTERACTION_STATES.Idle, AMC_INTERACTION_STATES.Disconnected],
    [WWE_INTERACTION_STATES.Ringing, AMC_INTERACTION_STATES.Alerting],
    [WWE_INTERACTION_STATES.Dialing, AMC_INTERACTION_STATES.Initiated],
    [WWE_INTERACTION_STATES.Talking, AMC_INTERACTION_STATES.Connected],
    [WWE_INTERACTION_STATES.Held, AMC_INTERACTION_STATES.OnHold],
    [WWE_INTERACTION_STATES.Stopped, AMC_INTERACTION_STATES.Disconnected],
    [WWE_INTERACTION_STATES.Invited, AMC_INTERACTION_STATES.Alerting],
    [WWE_INTERACTION_STATES.Accepted, AMC_INTERACTION_STATES.Connected],
    [WWE_INTERACTION_STATES.Completed, AMC_INTERACTION_STATES.Disconnected],
]);

export const CHANNEL_WWE_TO_AMC = new Map<string, number>([
    [WWE_CHANNEL_TYPES.Chat, CHANNEL_TYPES.Chat],
    [WWE_CHANNEL_TYPES.Voice, CHANNEL_TYPES.Telephony]
]);

export enum BROADCAST_MESSAGE_DIRECTION {
    None = 0,
    ToWwe = 1,
    FromWwe = 2
}

export interface IDetails {
    [key: string]: IField;
}