import { Component, OnInit, OnDestroy } from '@angular/core';
import { APP_CONTSTANTS, BROADCAST_MESSAGE_DIRECTION, WWEInteractionDirection, WWE_AGENT_REQUESTS, WWE_INTERACTION_REQUESTS } from '../models/AppConstants';
import { IMessage } from '../models/IMessage';
import { LoggerService } from '../_services/logger.service';

declare const genesys: any;


@Component({
  selector: 'app-wwe-hosted',
  templateUrl: './wwe-hosted.component.html',
  styleUrls: ['./wwe-hosted.component.css']
})
export class WweHostedComponent implements OnInit, OnDestroy {

  private _bc: BroadcastChannel = new BroadcastChannel('');
  private _fileName = 'wwe-hosted.component.ts';

  constructor(private _lgSrvc: LoggerService) {
    try {
      this._bc = new BroadcastChannel(APP_CONTSTANTS.BroadcastChannelName);
    } catch (error) {
      console.log(error);
    }

  }

  ngOnDestroy(): void {
    const fnName = 'ngOnDestroy';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this._bc.close();
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  ngOnInit(): void {
    const fnName = 'ngOnInit';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.subscribe();
      //Messages recieved from our softphone
      this._bc.onmessage = (message: any) => {
        if ((message.data as IMessage).messageDirection === BROADCAST_MESSAGE_DIRECTION.ToWwe) {
          this.processMessageFromSoftphone(message.data);
        }
      };

      let a = setInterval(() => {
        this.sendHeartBeat();
      }, 1000);

      this._bc.onmessageerror = (error) => {
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Processes the messages coming from AMC's softphone
  * @param broadcastedMessage broadcastedMessage: the message came from softphone
  */
  processMessageFromSoftphone(broadcastedMessage: IMessage) {
    const fnName = 'processMessageFromSoftphone';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      switch (broadcastedMessage.messageObject) {
        case APP_CONTSTANTS.MessageHeartBeat:
          this.sendHeartBeat();
          break;
        // case AppConstants.SUBSCRIBE:
        //   this.subscribe();
        //   break;
        case WWE_AGENT_REQUESTS.Get:
          this.agentGet();
          break;
        case WWE_INTERACTION_REQUESTS.GetAll:
          this.interactionGetInteractions();
          break;
        case WWE_AGENT_REQUESTS.GetState:
          this.agentGetState();
          break;
        case WWE_INTERACTION_REQUESTS.SetUserData:
          this.interactionSetUserData(broadcastedMessage);
          break;
        default:
          break;
      }
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    }
  }

  /**
  * Sends hearbeat message to AMC's softphone
  */
  sendHeartBeat(): void {
    const fnName = 'sendHeartBeat';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      let message: IMessage = {
        messageDirection: BROADCAST_MESSAGE_DIRECTION.FromWwe,
        messageObject: APP_CONTSTANTS.MessageHeartBeat
      }
      this._bc.postMessage(message);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends subscribe message to WWE
  */
  subscribe() {
    const fnName = 'subscribe';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.subscribe(['agent', 'interaction'], this.onEventReceived.bind(this), this);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE queying agent details
  */
  agentGet() {
    const fnName = 'agentGet';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.agent.get(this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }


  /**
  * Sends a request to WWE queying all the available presences
  */
  agentGetStateList() {
    const fnName = 'agentGetStateList';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.agent.getStateList(this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE to set an agent presence
  * **NOTE:** this is not used now!
  */
  agentSetState() {
    const fnName = 'agentSetState';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.agent.setState(0, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE queying agent state
  */
  agentGetState() {
    const fnName = 'agentGetState';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.agent.getState(this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE queying all interactions
  */
  interactionGetInteractions() {
    const fnName = 'interactionGetInteractions';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.getInteractions(this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE queying an interaction by its ID
  * **NOTE:** It is not used now!
  */
  interactionGetByInteractionId() {
    const fnName = 'interactionGetByInteractionId';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.getInteractions(0, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  // genesys.wwe.service.interaction.setUserData("1",
  //   {
  //     MyKEY1: "MyValue1",
  //     MyKEY2: "MyValue2"
  //   }
  // )

  /**
  * Sends a request to WWE to update an interaction
  *@param data data: an IMessage in which interactionID and keyValues are provided.
  */
  interactionSetUserData(data: IMessage) {
    const fnName = 'interactionSetUserData';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.setUserData(data.additionalData, data.keyValues, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE to delete userData for an interaction
  * **NOTE:** It is not used now!
  */
  interactionDeleteUserData() {
    const fnName = 'interactionDeleteUserData';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.deleteUserData(0, undefined, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Sends a request to WWE to select (focus) an interaction by its Id
  * @param id id: string of interactionId
  */
  interactionSelectCaseByCaseId(id: string) {
    const fnName = 'interactionSelectCaseByCaseId';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.selectCaseByCaseId(id, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * **NOTE:** It is not used now! Needs more documentation.
  */
  interactionMarkdone() {
    const fnName = 'interactionMarkdone';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.markdone(0, undefined, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * **NOTE:** It is not used now! Needs more documentation.
  */
  interactionBlockMarkdone() {
    const fnName = 'interactionBlockMarkdone';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.blockMarkdone(0, "no_warning_message", undefined, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * **NOTE:** It is not used now! Needs more documentation.
  */
  interactionUnblockMarkdone() {
    const fnName = 'interactionUnblockMarkdone';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      genesys.wwe.service.interaction.unblockMarkdone(0, undefined, this.succeeded.bind(this), this.failed.bind(this));
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Callback for event received from WWE
  */
  onEventReceived(data: any) {
    const fnName = 'onEventReceived';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.broadcastMessageToAMC(data);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Success callback for requests sent to WWE
  */
  succeeded(result: string) {
    const fnName = 'succeeded';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.broadcastMessageToAMC(result);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Success callback for requests sent to WWE
  */
  failed(result: string) {
    const fnName = 'failed';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      this.broadcastMessageToAMC(result, true);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

  /**
  * Broadcasts message to AMC's softphone
  * @param data data: the message!
  * @param failed failed: represents whether the request sent to WWE was failed or not!
  */
  broadcastMessageToAMC(data: any, failed: boolean = false) {
    const fnName = 'broadcastMessageToAMC';
    this._lgSrvc.logFnPerimeter(this._fileName, fnName, true);
    try {
      let message: IMessage = {
        messageDirection: BROADCAST_MESSAGE_DIRECTION.FromWwe,
        messageObject: data,
        failed: failed
      }
      this._bc.postMessage(message);
    } catch (error) {
      this._lgSrvc.logger.logError(`${error} from function ${fnName} in file ${this._fileName}`);
    } finally {
      this._lgSrvc.logFnPerimeter(this._fileName, fnName);
    }
  }

}
